<script setup>
import Billboard from "~/components/Billboard.vue"
import StyleCard from "~/components/cards/StyleCard.vue"
import BoxBanner from "~/components/banners/BoxBanner.vue"
import ValuePropositions from "~/components/ValuePropositions.vue"
import BlogCard from "~/components/cards/BlogCard.vue"
import StackBanner from "~/components/banners/StackBanner.vue"
import GridBanner from '~/components/banners/GridBanner.vue'
import ProductCard from '~/components/cards/ProductCard.vue'
import Button from '~/components/Button.vue'

const dairamon = useDairamon()
let { data:sectionsData } = await dairamon.pages.sections.list({ url: 'home', with_products: true, with_categories: true });

const breakpoint = ref()
const sections = ref([])
const products = ref([]);

for (const section of sectionsData.value.data) {
  // if (section.type === 'product_slider') {
  //   const { data } = await dairamon.products.get({ main: 1, limit: 10, sort: 'score' })
  //   section.values = data.value.products
  // }
  sections.value.push(section)
}


onMounted(() => {
  breakpoint.value = dairamon.getBreakpoint()
  dairamon.storage.setItem('drm_breakpoint', breakpoint.value);
})


const loadProducts = async () => {
  nextTick(async () => {
    const { data } = await dairamon.products.list();
    products.value = data.value.data;
  });
}
</script>

<template>
  <div class="home page">
    <template v-for="section in sections">
      
      <!-- BILLBOARD -->
      <template v-if="section.name === 'billboard'">
        <section class="billboard">
          <Carausel :data="section.values">
            <template #card="data"><Billboard :data="data"></Billboard></template>
          </Carausel>
        </section>
      </template>
      
      <!-- STYLES -->
      <template v-if="section.name === 'stilus'">
        <section class="style">
          <div class="header">
            <h2>{{ $t('section_title2') }}</h2>
            <Button variant="outline" size="small">{{ $t('btn_label_2') }}</Button>
          </div>
          <Slider :data="section.values">
            <template #card="data"><StyleCard :data="data"></StyleCard></template>
          </Slider>
        </section>
      </template>
      
      <!-- PRODUCT SLIDER -->
      <template v-if="section.type === 'product_slider'">
        <section class="style">
          <div class="header">
            <h2>{{ $t('section_title1') }}</h2>
            <Button variant="outline" size="small">{{ $t('btn_label_2') }}</Button>
          </div>
          <Slider :data="products" @mounted="loadProducts">
            <template #card="data"><ProductCard :data="data" parent="slider"></ProductCard></template>
          </Slider>
        </section>
      </template>
      
      <!-- PRODUCT -->
      <template v-if="section.name === 'product'">
        <section class="style">
          <ProductBanner :data="section"></ProductBanner>
        </section>
      </template>
      
      <!-- BLOG -->
      <template v-if="section.name === 'blog'">
        <section class="blog">
          <Slider :data="section.values">
            <template #card="data"><BlogCard :data="data"></BlogCard></template>
          </Slider>
        </section>
      </template>
      
      <!-- BOX BANNER -->
      <template v-if="section.type === 'banner' && section.template.name === 'box'">
        <section class="Banner">
          <BoxBanner :data="section"></BoxBanner>
        </section>
      </template>

      <!-- STACK BANNER -->
      <template v-if="section.type === 'banner' && section.template.name === 'stack'">
        <section class="Banner">
          <StackBanner :data="section"></StackBanner>
        </section>
      </template>

      <!-- GRID BANNER -->
      <template v-if="section.type === 'banner' && section.template.name === 'grid'">
        <section class="Banner">
          <GridBanner :data="section"></GridBanner>
        </section>
      </template>
    </template>
    <section class="ValueProposition"><ValuePropositions></ValuePropositions></section>
  </div>
</template>

<style scoped lang="scss">

.home {
  
  section {
    margin: 0;
    padding: 1rem 2rem;
    
    .header {
      padding-top: 4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .blog {
    height: 40rem;
  }
}
</style>
