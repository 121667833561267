<script setup>
const props = defineProps({
  data: { type: Object, required: true },
})
const card = props.data;
</script>

<template>
  <div class="blog-card card">
    <DImage :src="card.image?.billboard.src" :alt="card.title"></DImage>
    <div class="copy">
      <h1 v-if=card.title>{{ card.title }}</h1>
      <p v-if=card.style>{{ card.style }}</p>
    </div>
  
  </div>
</template>

<style scoped lang="scss">
.blog-card {
  position: relative;
  
  width: 420px;
  height: 260px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
    overflow: hidden;
  }
  
  .copy {
   // position: absolute;
    width: 100%;
    height: auto;
    padding: 2rem;
    bottom: 0;
    text-align: left;
    
    h1 {
      text-align: left;
      font-size: 2rem;
    }
    p {
      font-size: 1.3rem;
      color: var(--color2);
    }
  }
  

}

</style>
