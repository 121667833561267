<script setup>
import ValuePropositionCard from '~/components/cards/ValuePropositionCard.vue';
import ShippingIcon from '~/components/icons/ShippingIcon.vue'
import PaymentIcon from '~/components/icons/PaymentIcon.vue'
import SupportIcon from '~/components/icons/SupportIcon.vue'
import WarrantlyIcon from '~/components/icons/WarrantlyIcon.vue'

const data = [
  {
    icon: ShippingIcon,
    title: 'Országos kiszállítás',
    description: 'Gyors, megbízható és biztonságos kiszállítás az ország bármely területére',
  },
  {
    icon: WarrantlyIcon,
    title: 'Visszafizetési garancia',
    description: 'Ha nem elégedett teljes mértékben a termékével, visszaküldheti azt',
  },
  {
    icon: PaymentIcon,
    title: 'Biztonságos fizetés',
    description: 'Kiemelt figyelmet fordítunk pénzügyi és személyes adatai védelmére',
  },
  {
    icon: SupportIcon,
    title: 'Szakmai segétség',
    description: 'Tapasztalt kollégáink készséggel állnak az Ön szolgálatára.',
  },
];

</script>

<template>
  <div class="wrapper" grid>
    <ValuePropositionCard v-for="item in data" :data="item"></ValuePropositionCard>
  </div>
</template>

<style scoped lang="scss">
@import "@/styles/base/breakpoints.scss";

.wrapper {
  display: grid;
  //grid-template-columns: repeat(auto-fill, 1fr);
  grid-template-columns: repeat(var(--grid-cols), minmax(0, 1fr));
  grid-gap: 2rem;
}


</style>
