<script setup>
// import Button from "~/components/Button.vue";
//
import Button from "~/components/Button.vue";

const props = defineProps({
  data: { type: Object, required: true },
  background: { type: Boolean, default: false },
 // type: { type: String, default: 'default' },
 // size: { size: String, default: 'medium' },
})

const config = useRuntimeConfig();
const product = props.data;
</script>

<template>
  <div class="banner">
<!--    <img :src="config.public.dairamon.assetsUrl + 'products/' + product.images[2].src" alt="Nature Image #1">-->
    <div class="copy">
      <h3 v-if=product.title>{{ product.title }}</h3>
      <h1 v-if=product.price>{{ product.price }} Ft</h1>

      <!--//TODO Get button name from CMS-->
      <Button type="primary" size="large">Részletek</Button>
    </div>
<!--    <img v-if=props.background class='background' :src="config.public.dairamon.assetsUrl + 'products/' + product.images[2].src" alt="Nature Image #1">-->

  </div>

</template>

<style scoped lang="scss">

.banner {

  background-color: var(--color3);
  width: 100%;
  height: 60rem;
  border-radius: 1rem;
  position: relative;
  display: flex;
  align-items: center;
 // padding: 2rem;

  img {

    margin-left: 2rem;
    width: 60%;
    height: 70%;
    object-fit: cover;
    border-radius: 1rem;
    z-index: 2;

  }
  .copy {

    width: 40%;
    padding: 3rem;
    z-index: 2;
    h1 {
      color: var(--color1);
      text-align: left;
      padding-bottom: 1rem;
    }

    h3 {
      color: var(--color1);
      text-align: left;
      padding-bottom: 1rem;
    }


    //position: absolute;
    //width: 100%;
    //height: auto;
    //padding: 2rem;
    //bottom: 0;
    //text-align: left;
    //h1 {
    //  text-align: left;
    //  font-size: 2rem;
    //}
    //p {
    //  font-size: 1.3rem;
    //  color: var(--color2);
    //}
  }

  .background {
    position: absolute;

    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: .3;
    z-index: 0;
  }
}



</style>
