<template>
  <svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.943 3.15569C28.3172 3.52994 28.3172 4.13672 27.943 4.51098L25.8432 6.61078C34.5202 7.40846 41.3166 14.7076 41.3166 23.5926C41.3166 33.0107 33.6804 40.6468 24.2624 40.6468C14.8444 40.6468 7.20825 33.0107 7.20825 23.5926C7.20825 16.1253 12.0029 9.78426 18.6629 7.47234C19.1629 7.29877 19.709 7.5634 19.8826 8.0634C20.0561 8.56341 19.7915 9.10945 19.2915 9.28301C13.3792 11.3354 9.12492 16.9661 9.12492 23.5926C9.12492 31.9521 15.9029 38.7301 24.2624 38.7301C32.6219 38.7301 39.3999 31.9521 39.3999 23.5926C39.3999 15.8299 33.555 9.43085 26.0265 8.55686L27.9411 10.461C28.3164 10.8342 28.318 11.441 27.9448 11.8163C27.5716 12.1916 26.9648 12.1932 26.5895 11.82L22.9259 8.17643C22.7454 7.99688 22.6437 7.75287 22.6434 7.49825C22.643 7.24363 22.744 6.99934 22.9241 6.81929L26.5877 3.15569C26.9619 2.78144 27.5687 2.78144 27.943 3.15569ZM12.2932 16.906C12.2932 16.3767 12.7223 15.9476 13.2515 15.9476H31.4695C31.9987 15.9476 32.4278 16.3767 32.4278 16.906C32.4278 17.4352 31.9987 17.8643 31.4695 17.8643H14.2099V26.3152C14.2099 26.8445 13.7808 27.2735 13.2515 27.2735C12.7223 27.2735 12.2932 26.8445 12.2932 26.3152V16.906ZM15.897 20.3096C15.897 19.7803 16.326 19.3512 16.8553 19.3512H34.873C35.4023 19.3512 35.8313 19.7803 35.8313 20.3096V30.2593C35.8313 30.7886 35.4023 31.2177 34.873 31.2177H16.8553C16.326 31.2177 15.897 30.7886 15.897 30.2593V20.3096ZM30.7316 21.2679H20.9767V29.301H30.7316V21.2679ZM32.6483 29.301V21.2679H33.9147V29.301H32.6483ZM19.0601 29.301V21.2679H17.8136V29.301H19.0601ZM25.8639 23.9704C25.1438 23.9704 24.56 24.5542 24.56 25.2743C24.56 25.9944 25.1438 26.5782 25.8639 26.5782C26.5841 26.5782 27.1678 25.9944 27.1678 25.2743C27.1678 24.5542 26.5841 23.9704 25.8639 23.9704ZM22.6434 25.2743C22.6434 23.4957 24.0853 22.0538 25.8639 22.0538C27.6426 22.0538 29.0845 23.4957 29.0845 25.2743C29.0845 27.053 27.6426 28.4949 25.8639 28.4949C24.0853 28.4949 22.6434 27.053 22.6434 25.2743Z" fill="#090909"/>
  </svg>
</template>

<script>
export default {
  name: "WarrentlyIcon"
}
</script>

<style scoped>
</style>








