<script setup>
import Button from "~/components/Button.vue"

const props = defineProps({
  data: { type: Object, required: true },
})

const config = useRuntimeConfig()
const carousel = props.data;
const activeID = ref(0)

const onClick = () => {
  activeID.value++
  if (activeID.value >= carousel.length) {
    activeID.value = 0
  } else if (activeID.value < 0) {
    activeID.value = carousel.length - 1
  }
}

</script>

<template>
  
  <div class="carousel">
    <div class="pagination">
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
    </div>
    <template v-for="(item, i) in carousel">
      <slot name="card" v-bind="{item, currentID: i, activeID}"></slot>
    </template>

  </div>
</template>

<style scoped lang="scss">
@import "@/styles/base/breakpoints.scss";

.carousel {
  width: 100%;
  height: 500px;
  background-color: var(--color2);
  border-radius: var(--radius);
  position: relative;
  overflow: hidden;
  
  .pagination {
    position: relative;
    display: flex;
    height: 100%;
    z-index: 1;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: -2rem;
    gap: 1rem;
    .item {
      width: .8rem;
      height: .8rem;
      background: var(--color4);
      border-radius: 50%;
    }
    
    .item:first-child {
      background: var(--color1);
    }
  }
}

/* Desktop */
@include breakpoint('m') {
  
  .carousel {
    .pagination {
      
      justify-content: center;
      align-items: end;
      padding: 1rem;
      margin: 0;
      .item {
        width: 1rem;
        height: 1rem;
      }

    }
  }
}
</style>
