<script setup>
import Button from "~/components/Button.vue"

const props = defineProps({
  data: { type: Object, required: true },
})

const banner = props.data?.values;
const image = computed(() => {
  const type = props.data?.template?.type
  if (type === 'product') {
    return banner.images[0].thumb
  } else if (type === 'category') {
    return banner.image.default
  } else {
    return banner.image.billboard
  }
})

</script>

<template>
  <div class="banner">
    <div class="copy">
      <h3 v-if="banner.title">{{ banner.title }}</h3>
      <div v-if="banner.shortDescription" v-html="banner.shortDescription"></div>
      <Button variant="solid" size="large">{{ $t('btn_label_3') }}</Button>
    </div>
    <DImage v-if="image" :src="image.src" :alt="image.title"></DImage>
  </div>
</template>

<style scoped lang="scss">
.banner {
  background-color: var(--color3);
  width: 100%;
  height: 60rem;
  border-radius: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  // padding: 2rem;
  overflow: hidden;

  .copy {
    position: absolute;
    width: 40%;
    padding: 3rem;
    z-index: 2;
    h1 {
      color: var(--color1);
      text-align: left;
      padding-bottom: 1rem;
    }

    h3 {
      color: var(--color1);
      text-align: left;
      padding-bottom: 1rem;
    }

    //position: absolute;
    //width: 100%;
    //height: auto;
    //padding: 2rem;
    //bottom: 0;
    //text-align: left;
    //h1 {
    //  text-align: left;
    //  font-size: 2rem;
    //}
    //p {
    //  font-size: 1.3rem;
    //  color: var(--color2);
    //}
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
