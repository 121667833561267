<script setup>
import Button from "~/components/Button.vue"

const props = defineProps({
  data: { type: Object, required: true },
})

const banners = props.data.values
const largeBanner = banners[0]
const smallBanner1 = banners[1]
const smallBanner2 = banners[2]
const smallBanner3 = banners[3]

</script>

<template>
  <div class="wrapper">
    <div class="main item">
      <div class="content">
        <h3> {{ largeBanner.title }}</h3>
        <p> {{ largeBanner.count }} termék</p>
      </div>
      <div class="picture">
        <DImage v-if="largeBanner.image" :src="largeBanner.image.default.src" :alt="largeBanner.title"></DImage>
      </div>
    </div>
    <div class="more">
      <div class="item">
        <div class="content">
          <h3> {{ smallBanner1.title }}</h3>
          <p> {{ smallBanner1.count }} termék</p>
        </div>
        <div class="picture">
          <DImage v-if="smallBanner1.image" :src="smallBanner1.image.default.src" :alt="smallBanner1.title"></DImage>
        </div>      </div>
      <div class="item">
        <div class="content">
          <h3> {{ smallBanner2.title }}</h3>
          <p> {{ smallBanner2.count }} termék</p>
        </div>
        <div class="picture">
          <DImage v-if="smallBanner2.image" :src="smallBanner2.image.default.src" :alt="smallBanner2.title"></DImage>
        </div>      </div>
      <div class="item">
        <div class="content">
          <h3> {{ smallBanner3.title }}</h3>
          <p> {{ smallBanner3.count }} termék</p>
        </div>
        <div class="picture">
          <DImage v-if="smallBanner3.image" :src="smallBanner3.image.default.src" :alt="smallBanner3.title"></DImage>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/styles/base/breakpoints.scss";

.wrapper {
  width: 100%;
  height: 100rem;
  background-color: var(--color2);
  border-radius: var(--radius);
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  
  .item {
    background-color: #ececec;
    display: grid;
    position: relative;
    overflow: hidden;
    border-radius: var(--radius);
    
    .content {
      position: absolute;
      background-color: rgba(var(--color1-rgb), var(--card-opacity));
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;
      
      h3 {
        color: var(--color4);
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: .8rem;
      }
      
      p {
        color: var(--color4);
        text-align: center;
      }
    }
    
    .picture {
      position: absolute;
      inset: 0;
      
      img {
        display: block;
        
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
    }
  }
  
  .main {
    .content {
      h3 {
        font-size: 4.2rem;
        line-height: 4rem;
        margin-bottom: 1.6rem;
      }
    }
  }
  
  .more {
    display: grid;
    gap: 1rem;
    overflow: hidden;
  }
}

/* DESKTOP */
@include breakpoint('l') {

 .wrapper {
   grid-template-columns: 60% 40%;
   height: 50rem;
 }
}
</style>
