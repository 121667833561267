<template>
  <svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7398 12.4582C10.3529 12.4582 9.12492 13.6773 9.12492 15.2853V16.2915H37.8749V15.2853C37.8749 13.6773 36.6469 12.4582 35.26 12.4582H11.7398ZM39.7219 16.8905C39.7668 16.7795 39.7916 16.6582 39.7916 16.5311V15.2853C39.7916 12.7244 37.8081 10.5415 35.26 10.5415H11.7398C9.19169 10.5415 7.20825 12.7244 7.20825 15.2853V28.7978C7.20825 31.3586 9.19169 33.5415 11.7398 33.5415H21.9621C22.4913 33.5415 22.9204 33.1124 22.9204 32.5832C22.9204 32.0539 22.4913 31.6248 21.9621 31.6248H11.7398C10.3529 31.6248 9.12492 30.4057 9.12492 28.7978V22.0415H25.4166C25.9459 22.0415 26.3749 21.6124 26.3749 21.0832C26.3749 20.5539 25.9459 20.1248 25.4166 20.1248H9.12492V18.2082H38.8333C39.3625 18.2082 39.7916 17.7791 39.7916 17.2498C39.7916 17.1227 39.7668 17.0014 39.7219 16.8905ZM34.0684 20.7945C32.0446 20.7945 30.4417 22.3854 30.4417 24.2822V26.5195H37.6952V24.2822C37.6952 22.3854 36.0923 20.7945 34.0684 20.7945ZM39.6118 26.5195V24.2822C39.6118 21.2698 37.0928 18.8778 34.0684 18.8778C31.0441 18.8778 28.525 21.2698 28.525 24.2822V26.5195H26.4956C25.9664 26.5195 25.5373 26.9485 25.5373 27.4778V37.0879C25.5373 37.6172 25.9664 38.0462 26.4956 38.0462H41.6634C42.1926 38.0462 42.6217 37.6172 42.6217 37.0879V27.4778C42.6217 26.9485 42.1926 26.5195 41.6634 26.5195H39.6118ZM38.6415 28.4361C38.6455 28.4362 38.6495 28.4362 38.6535 28.4362C38.6575 28.4362 38.6615 28.4362 38.6654 28.4361H40.705V36.1295H27.454V28.4361H29.4714C29.4754 28.4362 29.4794 28.4362 29.4834 28.4362C29.4874 28.4362 29.4914 28.4362 29.4953 28.4361H38.6415ZM34.0683 30.0626C34.5975 30.0626 35.0266 30.4917 35.0266 31.0209V33.4987C35.0266 34.028 34.5975 34.457 34.0683 34.457C33.539 34.457 33.1099 34.028 33.1099 33.4987V31.0209C33.1099 30.4917 33.539 30.0626 34.0683 30.0626Z" fill="#090909"/>
  </svg>
</template>

<script>
export default {
  name: "PaymentIcon"
}
</script>

<style scoped>
</style>








