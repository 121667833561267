<script setup>
const props = defineProps({
  data: { type: Object, required: true },
})
const card = props.data.item;
</script>

<template>
  <div class="billboard-content" :class="{ active:props.data.currentID === props.data.activeID }">
    <DImage v-if="card.image" :src="card.image.billboard.src" :alt="card.title"></DImage>
    <div class="copy">
      <h1 v-if="card.title">{{ card.title }}</h1>
      <p v-if="card.description">{{ card.description }}</p>
      <Button class="button" variant="outline" size="medium">{{ $t('btn_label_1') }}</Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/styles/base/breakpoints.scss";

.billboard-content {
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  
  &.active {
    opacity: 1;
  }
  
  .copy {
    position: relative;
    border-radius: 1rem;
    height: 50%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    padding: 5rem;
    align-items: center;
    z-index: 10;
    h1, p {
      color: var(--color1);
    }
  }
  
  img {
    display: block;
    width: 100%;
    height: 50%;
    object-fit: cover;
  }
}

/* Desktop */
@include breakpoint('m') {
  .billboard-content {
    
    &.active {
      opacity: 1;
    }
    
    .copy {
      position: absolute;
      border-radius: 1rem;
      width: 30rem;
      background-color: var(--color4);
      
      top: 50%;
      right: 0;
      transform: translate(-20%, -50%);
      padding: 2rem;
 
    }
    
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

</style>
